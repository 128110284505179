import React from "react";
import { withStyles } from "@material-ui/core";
import { graphql } from "gatsby";

import Header from "components/Header/Header";
import HeaderLinks from "components/Header/HeaderLinks.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

//import { isLoggedIn } from "../services/auth";
import SEO from "../components/seo"

import { container } from "assets/jss/material-kit-pro-react.js";

import "assets/scss/material-kit-pro-react.scss?v=1.9.0";
import Title from "../components/SubPages/Title";
import VimeoEmbed from "../components/VimeoEmbed/VimeoEmbed";

const pageStyle = theme => ({
  background: {
    position: "fixed",
    left: "0",
    right: "0",
    top: "0",
    bottom: "0",
    zIndex: "1",
    overflow: "visible",
    backgroundImage: "url(" + require("../assets/img/background.gif") + ")",
    backgroundPosition: "center", //"0% 50%"
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "scroll",
    backgroundSize: "cover",    
  },
  main: {
    zIndex: "2",
//    height: "100vh",
    paddingTop: "5vh",
//    minHeight: "750px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "140px",
    }
  },
  sections: {
    zIndex: "20",
    display: "flex",
    justifyContent: "center",
    paddingBottom: "40px",
    color: "#ffffff"
  },
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    textAlign: "center",
    lineHeight: "1.9rem",
    fontWeight: 200,
    ...container
  },
  subTitle: {
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "#ffffff",
    cursor: "unset",
    "&:hover,&:focus,&:visited": {
      color: "#ffffff",
      cursor: "unset",
    }
  },
  note: {
    fontSize: "1.2rem",
    fontWeight: 300,
  },
  section: {
    margin: "50px 0 20px 0",
    fontSize: "1.5rem",
    fontWeight: 500,
    color: "#D1833E",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.25rem",
    }
  },
  gridItem: {
    marginBottom: "70px"
  },
  caption: {
    fontSize: "1.0rem",
    fontWeight: 400,
    padding: "5px 0 0 0"
  },  
  link: {
    fontSize: "1.2rem",
    fontWeight: 400,
    padding: "20px 0 0 0",
    textDecoration: "underline",
    color: "#D1833E",
    "&:hover,&:focus,&:visited": {
      textDecoration: "underline",
      color: "#D1833E",
    }
  },
});

class VideosPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { unlock: 0 };
    this.onUnlockClick = this.onUnlockClick.bind(this);
  }
  
  onUnlockClick = event => {
    if(event) {
      event.preventDefault();
    }
    this.setState({ unlock: this.state.unlock + 1 });
    return false;
  };

  componentDidMount() {
//    if(!isLoggedIn()) {
//      window.location = '/';
//    }
  }

  render() {
    const { classes, ...rest } = this.props;
    let session1 = this.props.data.session1.edges;
    let session2 = this.props.data.session2.edges;
    let session3 = this.props.data.session3.edges;
    let now = new Date();
    let showSession1 = this.state.unlock >= 3 ? true : (now.getTime() >= (new Date("2020-10-16T20:30:00.000-05:00")).getTime());
    let showSession2 = this.state.unlock >= 3 ? true : (now.getTime() >= (new Date("2020-10-17T20:30:00.000-05:00")).getTime());
    let showSession3 = this.state.unlock >= 3 ? true : (now.getTime() >= (new Date("2020-10-18T20:30:00.000-05:00")).getTime());
    return (
      <div>
        <SEO title="President's Retreat 2020" />
        <Header
          links={<HeaderLinks />}
//          fixed
          color="transparent"
//          changeColorOnScroll={{ height: 0, color: "white" }}
          {...rest}
        />
        <div className={classes.background}></div>
        <div className={classes.main}>
          <div className={classes.sections}>
            <div className={classes.container}>
              <GridContainer justify="center" alignItems="center">
                <GridItem xs={12}>
                  <Title />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6}>
                  <div className={classes.subTitle}><a className={classes.subTitle} href="#top" onClick={this.onUnlockClick}>Videos on Demand</a></div>
                  <div className={classes.note}>Available at the end of each session</div>
                </GridItem>
              </GridContainer>
              <div className={classes.section}>
                Session 1 – Friday, October 16
              </div>
              <GridContainer justify="center">
                {showSession1 === true && session1.map(({ node }) => {
                  return (
                    <GridItem key={node.vimeoId} className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                      <VimeoEmbed vimeoId={node.vimeoId} />
                      <div className={classes.caption}>{node.title}</div>
                      {node.link && <div className={classes.link}><a className={classes.link} href={node.link} download="Session 1 Notes (with answers)">Session 1 Notes (with answers)</a></div>}
                    </GridItem>
                  );
                })}
                {(showSession1 === false || session1.length <= 0) &&
                  <GridItem key="session1" className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                    <div className={classes.caption}>Available immediately following the session.</div>
                  </GridItem>
                }
              </GridContainer>

              <div className={classes.section}>
                Session 2 – Saturday, October 17
              </div>
              <GridContainer justify="center">
                {showSession2 === true && session2.map(({ node }) => {
                  return (
                    <GridItem key={node.vimeoId} className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                      <VimeoEmbed vimeoId={node.vimeoId} />
                      <div className={classes.caption}>{node.title}</div>
                      {node.link && <div className={classes.link}><a className={classes.link} href={node.link} download="Session 2 Notes (with answers)">Session 2 Notes (with answers)</a></div>}
                    </GridItem>
                  );
                })}
                {(showSession2 === false || session2.length <= 0) &&
                  <GridItem key="session2" className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                    <div className={classes.caption}>Available immediately following the session.</div>
                  </GridItem>
                }
              </GridContainer>

              <div className={classes.section}>
                Session 3 – Sunday, October 18
              </div>
              <GridContainer justify="center">
                {showSession3 === true && session3.map(({ node }) => {
                  return (
                    <GridItem key={node.vimeoId} className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                      <VimeoEmbed vimeoId={node.vimeoId} />
                      <div className={classes.caption}>{node.title}</div>
                      {node.link && <div className={classes.link}><a className={classes.link} href={node.link} download="Session 3 Notes (with answers)">Session 3 Notes (with answers)</a></div>}
                    </GridItem>
                  );
                })}
                {(showSession3 === false || session3.length <= 0) &&
                  <GridItem key="session3" className={classes.gridItem} xs={12} sm={12} md={6} lg={6}>
                    <div className={classes.caption}>Available immediately following the session.</div>
                  </GridItem>
                }
              </GridContainer>                            
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(pageStyle)(VideosPage);

export const query = graphql`
  query {
    session1: allContentfulPresidentsRetreatResource(filter: {tags: {in: "Session 1"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          link
          vimeoId
          vimeoThumbnail {
            childImageSharp {
              fluid(maxWidth: 480, maxHeight: 320, fit: COVER, cropFocus: ATTENTION, background: "#000000") {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    session2: allContentfulPresidentsRetreatResource(filter: {tags: {in: "Session 2"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          link
          vimeoId
          vimeoThumbnail {
            childImageSharp {
              fluid(maxWidth: 480, maxHeight: 320, fit: COVER, cropFocus: ATTENTION, background: "#000000") {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    session3: allContentfulPresidentsRetreatResource(filter: {tags: {in: "Session 3"}}, sort: {order: ASC, fields: order}) {
      edges {
        node {
          title
          link
          vimeoId
          vimeoThumbnail {
            childImageSharp {
              fluid(maxWidth: 480, maxHeight: 320, fit: COVER, cropFocus: ATTENTION, background: "#000000") {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`